"use client";

import { useGlobalUser } from "@hooks/global/useGlobalUser";
import { useEffect } from "react";

// 탭 간 상태 동기화를 위해 storage 이벤트 발생 시 rehydrate 호출 (https://hits-ai.atlassian.net/browse/ONE-5543)
export default function Rehydrate() {
  const update = (e: StorageEvent) => {
    if (e.key === useGlobalUser.persist.getOptions().name) {
      void useGlobalUser.persist.rehydrate();
    }
  };

  useEffect(() => {
    window.addEventListener("storage", update);
    return () => {
      window.removeEventListener("storage", update);
    };
  }, []);

  return null;
}
