"use client";

import useChannelTalk from "@shared/lib/channel-talk/use-channel-talk";
import { useEffect, useState } from "react";

export default function useCookieBanner() {
  const [open, setOpen] = useState(false);
  const channelTalk = useChannelTalk();

  const handdleAccept = (type: "accept" | "reject") => {
    if (type === "accept") {
      localStorage.setItem("acceptCookie", "true");
    } else {
      localStorage.setItem("acceptCookie", "false");
    }
    setOpen(false);
  };

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("acceptCookie") === null;
    if (isFirstVisit) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    //google analytics
    if (window.gtag === undefined || typeof window.gtag !== "function") {
      return;
    }
    const isAccepted = localStorage.getItem("acceptCookie") === "true";
    window.gtag("set", "send_page_view", isAccepted);
  }, [open]);

  useEffect(() => {
    //channel talk
    const isFirstVisit = localStorage.getItem("acceptCookie") === null;

    !isFirstVisit && channelTalk.bootChannelTalk();
  }, [open, channelTalk]);

  return { handdleAccept, open };
}
