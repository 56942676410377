import { Dialog, DialogProps } from "@hits/hits-ui/dialog";
import clsx from "clsx";
import {
  ComponentProps,
  MouseEvent,
  PropsWithChildren,
  forwardRef,
} from "react";
import { MdClose } from "react-icons/md";

const BaseModalWithTrigger = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<Dialog.DialogTriggerProps>
>(({ children, ...props }, ref) => {
  return (
    <Dialog.Trigger asChild ref={ref} {...props}>
      {children}
    </Dialog.Trigger>
  );
});
BaseModalWithTrigger.displayName = "BaseModalWithTrigger";

const BaseModalClose = ({
  children,
  onClick,
}: PropsWithChildren<{
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}>) => {
  return (
    <Dialog.Close asChild onClick={onClick}>
      {children}
    </Dialog.Close>
  );
};

const BaseModalTitle = ({
  children,
  step,
  totalStep,
}: PropsWithChildren<{ step?: string; totalStep?: string }>) => {
  return (
    <Dialog.Title className="relative mb-5 flex flex-col text-xl font-semibold text-gray-300">
      {typeof step === "string" && (
        <span className="text-xs text-gray-700">
          Step {step} / {totalStep}
        </span>
      )}
      <div className="flex items-center justify-between gap-8">{children}</div>
    </Dialog.Title>
  );
};

const BaseModalCloseButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button">
>(({ ...props }, ref) => {
  return (
    <button {...props} ref={ref}>
      <MdClose className="text-gray-c-400 h-6 w-6" />
    </button>
  );
});
BaseModalCloseButton.displayName = "BaseModalCloseButton";

const BaseModalFooterWhiteButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button">
>(({ ...props }, ref) => {
  return (
    <button
      {...props}
      className="border-gray-bg-c-300 text-gray-c-600 inline-flex h-[38px] w-full items-center justify-center gap-x-1 rounded-lg border bg-white px-3 py-2.5 text-[14px] leading-normal"
      ref={ref}
    >
      {props.children}
    </button>
  );
});
BaseModalFooterWhiteButton.displayName = "BaseModalFooterWhiteButton";

interface SubmitButtonWithLoadingUIProps
  extends React.ComponentProps<"button"> {
  isLoading?: boolean;
}

const SubmitButtonWithLoadingUI = forwardRef<
  HTMLButtonElement,
  SubmitButtonWithLoadingUIProps
>(({ isLoading, children, ...props }, ref) => {
  return (
    <button {...props} disabled={isLoading || props.disabled} ref={ref}>
      {!isLoading ? (
        children
      ) : (
        //   TODO 애니메이션 추가 필요
        <div className="flex justify-center gap-1">
          <div className="h-1 w-1 rounded-full bg-gray-400" />
          <div className="h-1 w-1 rounded-full bg-gray-400" />
          <div className="h-1 w-1 rounded-full bg-gray-400" />
        </div>
      )}
    </button>
  );
});
SubmitButtonWithLoadingUI.displayName = "SubmitButtonWithLoadingUI";

const BaseModalFooterBlueButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button"> & { isLoading?: boolean }
>(({ ...props }, ref) => {
  return (
    <SubmitButtonWithLoadingUI
      {...props}
      className="text-gray-w-100 inline-flex h-[38px] w-full items-center justify-center gap-x-1 rounded-lg bg-blue-700 px-3 py-2.5 text-[14px] leading-normal"
      ref={ref}
    >
      {props.children}
    </SubmitButtonWithLoadingUI>
  );
});
BaseModalFooterBlueButton.displayName = "BaseModalFooterBlueButton";

const BaseModalDescriptor = ({ children }: PropsWithChildren) => {
  return <Dialog.Description>{children}</Dialog.Description>;
};

const BaseModalContent = ({
  children,
  className,
  ...props
}: PropsWithChildren<Dialog.DialogContentProps>) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-20 bg-black/[.3] opacity-20 transition-opacity" />
      <Dialog.Content
        {...props}
        className={clsx(
          "bg-white-100 fixed left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 rounded-2xl p-6 shadow-[0px_4px_40px_-1px_rgba(138,154,177,0.67)]",
          className,
        )}
      >
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
};

const BaseModalFooter = ({
  cancelText = "취소",
  confirmText = "확인",
  onClickConfirm = () => {
    return;
  },
  onClickClose = () => {
    return;
  },
  isConfirmLoading = false,
  ...props
}) => {
  return (
    <div {...props} className="flex gap-3">
      <BaseModalClose>
        <BaseModalFooterWhiteButton onClick={onClickClose}>
          {cancelText}
        </BaseModalFooterWhiteButton>
      </BaseModalClose>
      <BaseModalFooterBlueButton
        isLoading={isConfirmLoading}
        onClick={onClickConfirm}
      >
        {confirmText}
      </BaseModalFooterBlueButton>
    </div>
  );
};

const BaseModal = ({ children, ...props }: PropsWithChildren<DialogProps>) => {
  return <Dialog.Root {...props}>{children}</Dialog.Root>;
};

BaseModal.FooterPurpleButton = BaseModalFooterBlueButton;
BaseModal.FooterWhiteButton = BaseModalFooterWhiteButton;
BaseModal.CloseButton = BaseModalCloseButton;
BaseModal.Descriptor = BaseModalDescriptor;
BaseModal.Close = BaseModalClose;
BaseModal.Title = BaseModalTitle;
BaseModal.Content = BaseModalContent;
BaseModal.Trigger = BaseModalWithTrigger;
BaseModal.Footer = BaseModalFooter;
export default BaseModal;
