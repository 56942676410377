"use client";

import RoundButton from "@hits/hits-ui/button/round.button";
import ConfirmDialog from "@hits/hits-ui/dialog/confirm.dialog";
import { createStore, useAtomValue, useSetAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { useTranslations } from "next-intl";
import { type ReactNode } from "react";

interface GlobalModalState {
  open: boolean;
  title?: ReactNode;
  contents: ReactNode;
  overlayClassName?: string;
  button?: ReactNode;
  buttonText?: string;
  onClose?: () => void;
}
const atomStore = createStore();

const ModalAtom = atomWithReset<GlobalModalState>({
  open: false,
  title: null,
  contents: null,
});

export function useGlobalModalV2() {
  const setModalState = useSetAtom(ModalAtom, { store: atomStore });
  const resetState = useResetAtom(ModalAtom, { store: atomStore });

  return { setModalState, resetState };
}

export default function GlobalModalV2() {
  const modalData = useAtomValue(ModalAtom, { store: atomStore });
  const modalReset = useResetAtom(ModalAtom, { store: atomStore });
  const t = useTranslations("GlobalModal");
  return (
    <ConfirmDialog.Root
      open={modalData.open}
      onOpenChange={() => {
        modalReset();
        modalData.onClose?.();
      }}
    >
      <ConfirmDialog.Content
        className="z-[9999] flex min-w-[432px] flex-col"
        overlayClassName={modalData.overlayClassName}
      >
        {modalData.title && (
          <ConfirmDialog.Title>{modalData.title}</ConfirmDialog.Title>
        )}
        {modalData.contents}
        <ConfirmDialog.Close asChild>
          {modalData.button || (
            <RoundButton className="w-full" color="blue" size="l">
              {modalData.buttonText || t("ok")}
            </RoundButton>
          )}
        </ConfirmDialog.Close>
      </ConfirmDialog.Content>
    </ConfirmDialog.Root>
  );
}
