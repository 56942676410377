"use client";

import RoundButton from "@hits/hits-ui/button/round.button";
import useCookieBanner from "@hooks/cookie/useCookieBanner";
import { useTranslations } from "next-intl";
import Link from "next/link";

export default function CookieBanner({ lang }: { lang: langParmas }) {
  const { open, handdleAccept } = useCookieBanner();

  const t = useTranslations("COOKIE");
  return (
    <>
      {open && (
        <div
          className="bg-gray-c-900/90 toturial-clickable pointer-events-auto fixed bottom-0 z-[100000001] flex w-screen items-center justify-center px-9 py-6 text-white"
          data-cy="CookieBanner"
        >
          <div className="lgtb:flex-row lgtb:gap-0 flex w-full max-w-[1200px] flex-col items-center justify-between gap-6">
            <span className="smtb:whitespace-pre-wrap leading-[140%]">
              {t.rich("summary", {
                privacy: (chunks) => (
                  <Link
                    href={
                      lang === "ko"
                        ? "https://docs.hyperlab.hits.ai/terms/privacy-policy"
                        : "https://docs.hyperlab.hits.ai/terms-en/privacy-policy"
                    }
                    target="_blank"
                  >
                    <strong className="cursor-pointer font-bold underline underline-offset-4">
                      {chunks}
                    </strong>
                  </Link>
                ),
                cookie: (chunks) => (
                  <Link
                    href={
                      lang === "ko"
                        ? "https://docs.hyperlab.hits.ai/terms/cookies"
                        : "https://docs.hyperlab.hits.ai/terms-en/cookies"
                    }
                    target="_blank"
                  >
                    <strong className="cursor-pointer font-bold underline underline-offset-4">
                      {chunks}
                    </strong>
                  </Link>
                ),
              })}
            </span>
            <div className="flex gap-3">
              <RoundButton
                className="w-[122px]"
                data-cy="CookieBanner-reject"
                size="m"
                onClick={() => handdleAccept("reject")}
              >
                {t("reject")}
              </RoundButton>
              <RoundButton
                className="w-[122px]"
                data-cy="CookieBanner-accept"
                size="m"
                onClick={() => handdleAccept("accept")}
              >
                {t("accept")}
              </RoundButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
