"use client";

import BaseModal from "@components/Modal/BaseModal";
import { createStore, useAtomValue, useSetAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { useTranslations } from "next-intl";
import type { ReactNode } from "react";

interface GlobalModalState {
  open: boolean;
  title?: ReactNode;
  contents: ReactNode;
  button?: ReactNode;
  closeButtonHide?: boolean;
  onConfirm?: () => void;
}

const ModalAtom = atomWithReset<GlobalModalState>({
  open: false,
  title: null,
  contents: null,
});
const atomStore = createStore();
const atomOptions = { store: atomStore };

/**
 *
 * @DEPRECATED useGlobalModalV2()를 사용하세요.
 */
export function useGlobalModal() {
  const setModalState = useSetAtom(ModalAtom, atomOptions);
  const resetState = useResetAtom(ModalAtom, atomOptions);

  return { setModalState, resetState };
}

// TODO ConfirmDialog를 사용하도록 수정합니다.
export default function GlobalModal() {
  const t = useTranslations();
  const modalData = useAtomValue(ModalAtom, atomOptions);
  const modalReset = useResetAtom(ModalAtom, atomOptions);

  return (
    <BaseModal modal={true} open={modalData.open} onOpenChange={modalReset}>
      <BaseModal.Content className="z-[9999] min-w-[330px] !px-6 !py-5">
        {modalData.title && (
          <BaseModal.Title>
            <span>{modalData.title}</span>
            {!modalData.closeButtonHide && (
              <BaseModal.Close>
                <BaseModal.CloseButton />
              </BaseModal.Close>
            )}
          </BaseModal.Title>
        )}
        <div className="text-gray-c-700 mb-6.5 whitespace-pre-line text-center text-base leading-[26px]">
          {modalData.contents}
        </div>
        <BaseModal.Close>
          {modalData.button ? (
            modalData.button
          ) : (
            <BaseModal.FooterPurpleButton onClick={modalData.onConfirm}>
              {t("GlobalModal.ok")}
            </BaseModal.FooterPurpleButton>
          )}
        </BaseModal.Close>
      </BaseModal.Content>
    </BaseModal>
  );
}
