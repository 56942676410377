"use client";

import RoundButton from "@hits/hits-ui/button/round.button";
import { useGlobalUser } from "@hooks/global/useGlobalUser";
import { useGlobalModalV2 } from "@shared/ui/modal/global-modal-v2";
import {
  MutationCache,
  type MutationMeta,
  type Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import { useState } from "react";

// TODO 전체적인 에러 처리 리팩토링 필요
function isError(error: unknown): error is {
  error: { code: number; message: string };
  status: number;
  response: Response;
  meta?: MutationMeta;
} {
  return typeof error === "object" && error !== null && "error" in error;
}

type AppRouter = ReturnType<typeof useRouter>;
// isNotLoggedIn: 1,
// isSessionExpired: 2,
// isNotConsentTerms: 4
// 회원가입 로직 변경 후, 동의 안한 경우가 없을것 같아 제거. 필요 시 terms-consent 복구
const errorQueryKeyMap = new Map<string, boolean>();
function handleError(
  error: unknown,
  router: AppRouter,
  client: QueryClient,
  sessionTimeOutModalOpener: () => void,
  query?: Query,
) {
  if (isError(error)) {
    if (error.meta?.skipGlobalError) {
      return;
    }

    if (error.response.status === 401 || error.response.status === 403) {
      if (
        query &&
        error.response.status === 401 &&
        !errorQueryKeyMap.get(query.queryHash)
      ) {
        client.removeQueries({ queryKey: query.queryKey });
        errorQueryKeyMap.set(query.queryHash, true);
      }
      switch (error.error.code) {
        case 0:
          if (error.response.status === 403) {
            router.push(`/lab/auth/ip-restriction`);
          }
          break;
        case 1:
          useGlobalUser.getState().reset();
          router.refresh();
          break;
        case 2:
          if (!window.location.pathname.includes("/lab-list")) {
            sessionTimeOutModalOpener();
          }
          break;
      }
    }
    if (error.response.status === 404) {
      // return handleNotFound();
    }
  }
}
export default function ReactQueryProvider({
  children,
  params,
}: LangLayoutProps) {
  const { lang } = params;
  const router = useRouter();
  const { setModalState } = useGlobalModalV2();
  const t = useTranslations("SESSION_TIMEOUT");

  const handleSessionTimeout = () => {
    // https://hits-ai.atlassian.net/wiki/spaces/ONEPLATFOR/pages/322502657/OTP+-#1.3.-OTP-%EC%9E%85%EB%A0%A5-%ED%9B%84-%ED%99%94%EB%A9%B4-%EC%9D%B4%EB%8F%99
    // session timeout이 발생되면 OTP입력 후 현재 특정페이지로 리다이렉트 되어야한다.
    // todo 현재 페이지를 기억하고 있어야한다.
    // otp입력 후 현재 페이지로 이동되어야한다.
    setModalState({
      open: true,
      title: t("title"),
      contents: (
        <div className="body2-medium text-gray-c-700 my-10 whitespace-pre-line text-center">
          {t("content")}
        </div>
      ),
      button: (
        <RoundButton className="w-full" color="blue" size="l">
          {t("confirm")}
        </RoundButton>
      ),
      onClose: () => {
        // otp페이지로 이동하고 redirectURL을 가져감
        const pathname = window.location.pathname;
        const match = pathname.match(/(?<=\/lab\/)\d+/);
        if (match) {
          const labId = match[0];
          window.location.href = `/${lang}/lab/auth/2fa?labSpaceId=${labId}&redirectUrl=${pathname}`;
        } else {
          window.location.href = `/${lang}/lab-list`;
        }
      },
    });
  };

  const [client] = useState(() => {
    const queryClient = new QueryClient({
      queryCache: new QueryCache({
        onError: (error, query) => {
          handleError(error, router, queryClient, handleSessionTimeout, query);
        },
      }),
      mutationCache: new MutationCache({
        onError: (error) => {
          handleError(error, router, queryClient, handleSessionTimeout);
        },
      }),
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: 10000,
          networkMode:
            process.env.NODE_ENV === "development" ? "always" : "online",
          retry: 2,
        },
      },
    });

    return queryClient;
  });

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
}
